<template>
  <v-row class="mx-1">
    <div style="width: 100%; height: 75%" :style="getStyle"></div
  ></v-row>
</template>

<script>
import { $startOf, $difference } from '@/utils/datetime'
export default {
  props: {
    startDate: {
      type: String,
      default: '2021-05-01'
    },
    endDate: {
      type: String,
      default: '2021-08-01'
    }
  },
  computed: {
    getStyle() {
      const startOfYear = $startOf('year')
      // start Of Year - Start Of Campaign : White Color
      let beforeCampaignStart = $difference(this.startDate, startOfYear, 'day')
      beforeCampaignStart = Math.abs((beforeCampaignStart / 365) * 100).toFixed(2)
      if (new Date() > Date.parse(this.endDate)) {
        // No during before and after today, only orange color and no green color
        let duration = $difference(this.endDate, this.startDate, 'day')
        duration = Math.abs((duration / 365) * 100).toFixed(2)
        console.log('dura', duration)
        return {
          background: `linear-gradient(to right, white 0%, white ${beforeCampaignStart}%, #16BA23 ${parseInt(beforeCampaignStart)}%, #16BA23 ${parseInt(beforeCampaignStart) + parseInt(duration)}%, white ${parseInt(beforeCampaignStart) + parseInt(duration)}%, white 100%)`
        }
      } else if (new Date() < Date.parse(this.startDate)) {
        let duration = $difference(this.endDate, this.startDate, 'day')
        duration = Math.abs((duration / 365) * 100).toFixed(2)
        console.log('dura', duration)
        return {
          background: `linear-gradient(to right, white 0%, white ${beforeCampaignStart}%, #F2994B ${parseInt(beforeCampaignStart)}%, #F2994B ${parseInt(beforeCampaignStart) + parseInt(duration)}%, white ${parseInt(beforeCampaignStart) + parseInt(duration)}%, white 100%)`
        }
      } else {
        let duringBeforeToday = $difference(new Date().toISOString(), this.startDate, 'day')

        duringBeforeToday = Math.abs((duringBeforeToday / 365) * 100).toFixed(2)
        // Today's Date - End Of Campaign: Green
        let duringAfterToday = $difference(this.endDate, new Date().toISOString(), 'day')
        duringAfterToday = Math.abs((duringAfterToday / 365) * 100).toFixed(2)
        return {
          // background: `linear-gradient(to right, white 0%, white ${beforeCampaignStart}%, #F2994B ${parseInt(beforeCampaignStart)}%, #F2994B ${parseInt(beforeCampaignStart) + parseInt(duringBeforeToday)}%, #16BA23 ${parseInt(beforeCampaignStart) + parseInt(duringBeforeToday)}%, #16BA23 ${parseInt(beforeCampaignStart) + parseInt(duringBeforeToday) + parseInt(duringAfterToday)}%,  white ${parseInt(beforeCampaignStart) + parseInt(duringBeforeToday) + parseInt(duringAfterToday)}%)`
          background: `linear-gradient(to right, white 0%, white ${beforeCampaignStart}%, #16BA23 ${parseInt(beforeCampaignStart)}%, #16BA23 ${parseInt(beforeCampaignStart) + parseInt(duringBeforeToday)}%, #F2994B ${parseInt(beforeCampaignStart) + parseInt(duringBeforeToday)}%, #F2994B ${parseInt(beforeCampaignStart) + parseInt(duringBeforeToday) + parseInt(duringAfterToday)}%,  white ${parseInt(beforeCampaignStart) + parseInt(duringBeforeToday) + parseInt(duringAfterToday)}%)`
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.percentageFill {
  background: linear-gradient(
    to right,
    #c4d7e6 0,
    #c4d7e6 33%,
    #17b338 33%,
    #66a5ad 66%,
    #ff0000 66%,
    #ff0000 100%
  );
}
</style>
